import { dev } from '$app/environment';
import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
  dsn: 'https://b3939f99ced2eb957ef0548358d65929@o4506587303378944.ingest.sentry.io/4506587304361984',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: dev ? 0.1 : 0.25,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [
    new Replay({
      blockAllMedia: false,
      maskAllInputs: false,
      maskAllText: false
    })
  ],
  environment: dev ? 'development' : 'production'
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
